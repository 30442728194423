import { useNewSettingsStore } from '~/store/newSettingsStore'
import { fetchFromProd } from '~/helpers/api'
import { bffLink } from '~/constants/index'

export const useUser = () => {
  const settingsStore = useNewSettingsStore()

  const getUserPreferences = async () => {
    const requestHeaders = useRequestHeaders(['x-forwarded-for', 'x-real-ip'])
    const languageId = getI18nLanguageId()

    const headers = {};
    if ( requestHeaders['x-forwarded-for'] )
      headers['x-forwarded-for'] = requestHeaders['x-forwarded-for']

    try {
      const response = await $fetch(`/api/website/user-preferences?LanguageId=${languageId}`, {
        headers,
      })
      settingsStore.setUserPreferences(response)
    } catch (error) {
      console.error('Failed to fetch user preferences:', error)
    }
  }

  const getUserDiamondPreferences = async () => {
    try {
      const response = await $fetch('/api/v1/shop/diamond-preferences', {
        query: {
          currencyId: settingsStore.currentCurrency?.Id,
        },
      })
      settingsStore.setDiamondPreferences(response)
    } catch (error) {
      console.error('Failed to fetch user preferences:', error)
    }
  }

  const getUser = async () => {
    try {
      const response = await fetchFromProd(`${bffLink}/v1/account/me`, {
        method: 'GET',
        credentials: 'include', // equivalent to axios.defaults.withCredentials = true
      })
      return response && response.status == 200
    } catch (error) {
      console.error(error)
      return false
    }
  }

  return {
    getUserPreferences,
    getUserDiamondPreferences,
    getUser,
  }
}
