import { fetchTranslation } from '~/api/translations'

const commonPageId = 131
const shopPageId = 5

export async function setCommonTranslations(nuxtApp, locale) {
  try {
    // TODO: use some init logic here, after we decide how to get first localCode and other user related data
    // Fetch the common translations for the new locale
    const commonTranslations = await fetchTranslation({ id: commonPageId, languageCode: locale })
    const shopTranslation = await fetchTranslation({ id: shopPageId, languageCode: locale })
    if (!commonTranslations) {
      // TODO: think about other way to notify or hide it
      // maybe it's not necessary, but we need some way to ensure that we are notified about absent translations
      throw new Error('No common translations found')
    }
    const translation = nuxtApp.$i18n.getLocaleMessage(locale)

    // Update Nuxt i18n locale messages, common overrides page translations, change if needed
    nuxtApp.$i18n.setLocaleMessage(locale, { ...commonTranslations, ...shopTranslation, ...translation })
  } catch (error) {
    console.error('Failed to load translations:', error)
  }
}
