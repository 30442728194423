import { salutations } from '@/constants/utilConstants'
// import { getCategory } from './categories.js'
// import { getSubCategory } from './subCategories.js'
// import { getMetalById } from './metals.js'
// import { capitalize } from './formatting.js'
import ringSizes from '@/data/ringSizes.json'
import { getAttributes } from '~/utils/shop/attributes.js'
import { useNewSettingsStore } from '~/store/newSettingsStore.ts'
import { CERTIFICATES } from '~/constants/gemstones'

const _widths = ringSizes.widths

const getSettingsStore = () => {
  return useNewSettingsStore()
}

export function findBy(obj, key, value) {
  isNaN(value) && console.log('findBy', obj, key, value)
  if (isNaN(value)) {
    return obj.find((o) => o[key] == value)
  }
  return obj.find((o) => o[key] == value)
}

export function join(obj, field, separator, field2) {
  // _77T.T('round').toLowerCase()
  return obj
    .map((s) => (field2 ? [s[field2], s[field]] : s[field]))
    .flat()
    .filter((i) => i)
    .join(separator)
}

export const getValues = (obj) => {
  const _obj = {}
  Object.keys(obj)
    .filter((key) => obj[key] !== null && obj[key] !== undefined)
    .forEach((key) => {
      _obj[key] = obj[key]
    })
  return _obj
}

export function removeDuplicated(obj, key = 'Value') {
  return [...new Set(obj.map((size) => size[key]))].map((size) => obj.find((s) => s[key] == size))
}

export function removeDuplicatedComplex(obj, key = ['Value']) {
  return [...new Set(obj.map((size) => key.map((k) => size[k]).join('.')))].map((size) =>
    obj.find((s) => key.map((k) => s[k]).join('.') == size),
  )
}

export function loadScript(url, callback) {
  var script = document.createElement('script')
  script.type = 'text/javascript'
  if (script.readyState) {
    // only required for IE <9
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = function () {
      callback()
    }
  }

  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}

// TODO: handle code with jQuery
// export function isInViewport(el) {
//   if (!el.length) return false
//
//   let elementTop = el.offset().top
//   let elementBottom = elementTop + el.outerHeight()
//
//   let viewportTop = $(window).scrollTop()
//   let viewportBottom = viewportTop + $(window).height()
//
//   return elementBottom > viewportTop && elementTop < viewportBottom
// }

export function expressSetting(prop) {
  return window?._77Settings.Express[prop]
}

export function isGroupedShape(shape) {
  shape = parseInt(shape)
  let ga = window?._77Settings?.NaturalDiamondsFilters.GroupedShapes.GroupedAttributes.some((g) =>
    g.Attributes.some((a) => a.Id == shape),
  )

  // return (this.opened[0].StoneTypeID == 1 && ga);
  return ga
}

export function getShapeGroupe(shape) {
  shape = parseInt(shape)
  let ga = window?._77Settings?.NaturalDiamondsFilters.GroupedShapes.GroupedAttributes.find((g) =>
    g.Attributes.some((a) => a.Id == shape),
  )

  // return (this.opened[0].StoneTypeID == 1 && ga);
  return ga
}

export function getGemTypeName(gemType, doubleChar = false) {
  if (gemType == 366) return doubleChar ? 'RU' : 'R'
  else if (gemType == 367) return doubleChar ? 'SA' : 'S'
  else if (gemType == 371) return 'EM'
}

export function gemRealName(gemId) {
  switch (gemId) {
    case 367:
      return 'sapphire'
    case 371:
      return 'emerald'
    default:
      return 'ruby'
  }
}

export function isEcoCert(certId, stoneType) {
  var stone = parseInt(stoneType)
  if ((certId == CERTIFICATES.GIA && stone == 3) || (certId == CERTIFICATES.LGC && stone == 2)) {
    return true
  }

  return false
}

export function certifHasInfo(certId) {
  const infoCerts = [
    CERTIFICATES.ICL,
    CERTIFICATES.GCAL,
    CERTIFICATES.DF,
    CERTIFICATES.IGI,
    CERTIFICATES.HRD,
    CERTIFICATES.EGL,
    CERTIFICATES.GIA,
    CERTIFICATES.LGC,
  ]

  if (infoCerts.indexOf(certId) > -1) {
    return true
  }

  return false
}

export function ga4FilterReset(category = '', value) {
  try {
    let valueText = ''
    if (value) {
      if (value.includes(',')) {
        value = value.split(',')
        valueText = `${value[0]} to ${value[value.length - 1]}`
      } else if (value.length == 0) {
        valueText = ''
      } else {
        valueText = value
      }
    }
    window.DataLayer.productListingFilterInteraction(category, valueText, 'Remove')
  } catch (error) {
    console.warn(`error calling datalayer.${category}`, value)
  }
}

// TODO: use nuxt route instead of vm.$route
// export function ga4_madeByYouStepChange() {
//   try {
//     const { name, query } = vm.$route
//     let step = '1'
//     let stepName = ''
//     let category = translate(getCategory(query.category).label)
//
//     if (name == 'item' || name == 'category') {
//       if (query.diamond) step = '2'
//       else step = '1'
//
//       stepName = 'Select Your Setting'
//     } else if (name.indexOf('diamond') != -1) {
//       if (query.diamond) step = '1'
//       else step = '2'
//       stepName = 'Diamond'
//     } else if (name == 'item-finish') {
//       step = '3'
//       stepName = 'Finish'
//     }
//
//     dataLayerPush({
//       event: 'madeByYouStepChange',
//       madeByYou: {
//         stepNumber: step,
//         stepName: stepName,
//         productType: category,
//       },
//     })
//   } catch (error) {
//     console.warn(`error calling datalayer.madeByYouStepChange`, vm.$route)
//   }
// }

export function ga4_productReviewInteraction(action) {
  try {
    dataLayerPush({
      event: 'productReviewInteraction',
      productReview: {
        action: action,
      },
    })
  } catch (error) {
    console.warn(`error calling datalayer.ga4_productReviewInteraction`, action)
  }
}

export function ga4_virtualRingStylerInteractions(action, selection) {
  try {
    dataLayerPush({
      event: 'virtualRingStylerInteractions',
      virtualRingStyler: {
        action: action,
        selection: selection,
      },
    })
  } catch (error) {
    console.warn(`error calling datalayer.ga4_virtualRingStylerInteractions`, action, selection)
  }
}

// require getProductDataLayer
export function ga4_productObject(event = '', item = null, index = '', items = null, diamonds = null) {
  //   try {
  //     if (item) {
  //       const product = getProductDataLayer(item, index)
  //
  //       dataLayerPush({
  //         event: event,
  //         product: [
  //           {
  //             ...product,
  //           },
  //         ],
  //       })
  //     } else if (items && items.length > 0) {
  //       let products = []
  //       for (let index = 0; index < items.length; index++) {
  //         const item = items[index]
  //         const product = getProductDataLayer(item, index)
  //         products.push(product)
  //       }
  //
  //       dataLayerPush({
  //         event: event,
  //         product: products,
  //       })
  //     } else if (diamonds && diamonds.length > 0) {
  //       let products = []
  //       for (let index = 0; index < diamonds.length; index++) {
  //         const diamond = diamonds[index]
  //         const product = getDiamondDataLayer(diamond, index)
  //         products.push(product)
  //       }
  //
  //       dataLayerPush({
  //         event: event,
  //         product: products,
  //       })
  //     }
  //   } catch (error) {
  //     console.log(error)
  //     console.warn(`error calling datalayer.ga4_productObject`, event)
  //   }
}

// TODO: use nuxt route instead of vm.$route
// export function getProductDataLayer(item, index) {
//   const { query } = vm.$route
//   let category = capitalize(translate(getCategory(query.category).label)) || ''
//   let itemCategory = capitalize(translate(getCategory(item.Category || item.CategoryId).item_label)) || ''
//   let subCategory = capitalize(translate(getSubCategory(item.SubCategory || item.SubCategoryId).label)) || ''
//   let itemSubCategory = capitalize(translate(getSubCategory(item.SubCategory || item.SubCategoryId).item_label)) || ''
//   let metal = capitalize(translate(getMetalById(item.Metal || query.metal).label))
//   let price = item.Price.Price
//   let name = item.Name
//   let discount = ''
//
//   if (query && query.diamond) {
//     name = `${name} with ${query.diamond}`
//   } else if (price && price.DiamondsPrice && price.DiamondsPrice.length > 0) {
//     let diamonds = price.DiamondsPrice.map((d) => d.UniqueCode).join(', ')
//     name = `${name} with ${diamonds}`
//   }
//
//   if (query && query['discount-code']) {
//     discount = query['discount-code']
//   }
//
//   var product = {
//     id: item.Id,
//     name: name,
//     brand: '77Diamonds',
//     price: price.TotalPrice.FinalPrice.NumericPrice.WithVat,
//     quantity: '1',
//     index: index.toString(),
//     promotionName: '',
//     discount: discount,
//     affiliation: '',
//     itemListName: category || itemCategory || '',
//     categories: [itemSubCategory, itemCategory, subCategory],
//     variant: [metal, item.DiamondShape ? 'Diamond' : ''],
//   }
//
//   return product
// }

// TODO: use nuxt route instead of vm.$route
// export function getDiamondDataLayer(item, index) {
//   const { query } = vm.$route
//   let shape = item.ShapeName
//   let stoneType = item.StoneTypeName
//   let price = item.FinalSalePrice
//   let name = item.Code
//   let discount = ''
//
//   if (query && query['discount-code']) {
//     discount = query['discount-code']
//   }
//
//   var product = {
//     id: item.Id,
//     name: name,
//     brand: '77Diamonds',
//     price: price,
//     quantity: '1',
//     index: index.toString(),
//     promotionName: '',
//     discount: discount,
//     affiliation: '',
//     itemListName: stoneType,
//     categories: ['Diamond'],
//     variant: [shape, stoneType],
//   }
//
//   return product
// }

export function dataLayerPush(data) {
  try {
    if (window.DataLayer) {
      window.DataLayer.push(data)
    }
  } catch (error) {
    console.warn(`error calling datalayer.dataLayerPush`, data)
  }
}

export function microsoftTagsPush(eventName, eventValue) {
  try {
    if (eventName) {
      window.uetq = window.uetq || []
      window.uetq.push('event', eventName, eventValue || {})
    }
  } catch (error) {
    console.warn(`error calling uetq.push`, eventName, eventValue)
  }
}

export function getSalutations() {
  const language = window._77Settings.LanguageId
  const currentSalutations = salutations.filter((s) => s.languageId == language)

  if (!currentSalutations || currentSalutations.length == 0) return salutations.filter((s) => s.languageId == 1)

  return currentSalutations
}

export function imageLoadError(e) {
  console.log('imageLoadError', e)
  if (e?.target) {
    e.target.src = '/Assets/images/shop/no_image_placeholder.png'
  }
}

// Use newSettingsStore instead
export function getVatInfo() {
  return window._77Settings.Country
}

export function hasDeko(orderValue = 0) {
  return (
    getSettingsStore().CountryId == '826' &&
    getSettingsStore().Currency.Id == '1' &&
    orderValue < getMaxOrderValueForFinance()
  )
}

// TODO: use API to get settings or it can be hardocoded
export function getMaxOrderValueForFinance() {
  // return window._77Settings.MaxLendValue
  return 38000 // copied form prod
}

export function stoneTypeLabel(stonetype) {
  if (stonetype == 1) return 'diamond'
  else if (stonetype == 3) return 'lab-grown-diamond'
  else if (stonetype == 2) return 'gemstone'
  return null
}

export function isPromoBanner() {
  let searchParams = new URLSearchParams(window?.location.search)

  if (searchParams.has('rtcampaign')) {
    let param = searchParams.get('rtcampaign')

    if (param == 'promo') {
      sessionStorage.setItem('rtcampaign', param)
      return true
    }
  } else if (sessionStorage.getItem('rtcampaign') != null) {
    return true
  } else {
    return false
  }
}

export function formatTodaysDate() {
  const d = new Date()

  const month = d.getMonth() + 1
  const day = d.getDate()
  const h = d.getHours(),
    m = d.getMinutes()

  let output = d.getFullYear() + '/' + (month < 10 ? '0' : '') + month + '/' + (day < 10 ? '0' : '') + day + ' '
  output += h > 12 ? h + ':' + m + ' PM' : h + ':' + m + ' AM'

  return output
}

export function hideQuickShipping() {
  const currentDate = formatTodaysDate()
  const selectedDate = '2021-01-04T00:00:00.000Z'

  if (Date.parse(currentDate) <= Date.parse(selectedDate)) {
    return false
  } else {
    return true
  }
}

export function compare(a, b) {
  return JSON.stringify(a) === JSON.stringify(b)
}

export function getDepths() {
  return getAttributes('depth')
}

export function getWidths() {
  return _widths
}

export function openBespoke(diamond) {
  // TODO: add form
  return ''
  let message = 'Type: ' + diamond.StoneTypeName + '\nCode: ' + diamond.Code + '\nShape: ' + diamond.ShapeName

  let form = `
            <form action="${_77T.L('/NewApp/Default.aspx?app=bespoke')}" method="POST" target="_blank">
                <input type="hidden" name="message" value="${message}">
            </form>
        `
  $(form).appendTo('body').submit()
}
